/* COLOR SWATCHES
Primary Colors (Blue): 
  #6DA4F8   #9EC3FA   #E7F0FE

Accent Color (Pink): 
  #FD9BAA   #FDB4BF   #FFE6EA

Greens:  
  #7DDFC3   #ADEBD9   #D6F5EC

Red-Alert:  
  #F27373   #FAB8B8   #FDE7E7

Grays:
  #5C6070   #B9BEC6   #E3E5E8
*/


/*  TYPOGRAPHY
Heading 1: Roboto Bold (48px)
Heading 2: Roboto Medium (36px)
Heading 3: Roboto Bold (28px)
Body: Roboto Regular (16px)
Search Text: Roboto Regular (16px)
Button Text: Roboto Medium (16px)
*/

body {
  background:#F9FBFC !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*GLOBAL STYLES*/

.logo {
  display: block;
  max-width: 130px;
  max-height: 110px;
  padding-left: 5%;
  width: auto;
  height: auto;
}


/* LANDING PAGE & SIGN-UP & LOG-IN */

h3 {
  font-family: "Roboto Bold", sans-serif;
  font-size: 16px;
  color: #5C6070;
}

.login-fields form{
  display:flex;
  flex-direction:column;
  width: 80%;
  margin:0 auto;
  
}

.sign-up-form{
  width:80%;
  margin:0 auto;
  padding:2% 0%;
}

.login-form{
  padding:2% 0%;
}

.login-input-fields{
  background:white;
  border:1px solid #B9BEC6;
  border-radius: 4px;
  width:60%;
  margin:0 auto;
  padding:2% 0%;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
}

.login-input-fields input{
  width:70%;
    border-radius:8px;
    background:#f9fbfc;
    margin:2% 0%;
    padding: 2% 2%;
  
}

.icon-logo{
  color:#6da4f8;
  padding:2% 1%;
}

.sign-up-fields{
  background:white;
  border:1px solid #B9BEC6;
  border-radius: 4px;
  width:60%;
  margin:0 auto;
} 


.form-input input  {
    width:70%;
    border-radius:8px;
    background:#f9fbfc;
    margin:2% 0%;
    padding: 2% 2%;
}

.button-style-main {
  width: 30%;
  color: #6DA4F8;
  font-weight: bold;
  background-color: white;
  border: 2px solid #6DA4F8;
  border-radius: 5px;
  padding:1% 0%;
  margin:2% auto;
}

.button-style-main:active {
  color: white;
  background-color: #6DA4F8;
}

.button-style-main.trip-button{
  width:20%;
}

.side-button{
  margin: 2% 1%;
}

/* LANDING PAGE */

.landing-page {
  width: 100%;
  height: 100%;
}

.landing-div {
  /* margin-top: 20px; */
}

h1 {
  font-size: 65px !important;
  font-family: "Roboto Bold", 'sans-serif';
  color: #6DA4F8;
}

.header-h1 {
  padding-right: 5%;
}

.landing-div h4,p {
  font-size: 18px;
}

.landing-div button {
  width: 40%;
  background-color: #6DA4F8;
  color: white;
  margin-bottom: 5px;
}

.sign-in-div {
  width: 45%;
  margin: 0 auto;
  margin-top: 0px;
  display: flex;
  justify-content: center;
}

.sign-in-div p {
  line-height: center;
}


.sign-in-link {
  font-size: 18px;
  vertical-align: middle;
  line-height: 100%;
  padding-left: 10px;
}




/* EXPENSES PAGES */

.expenses-list {
  background: #F7F9FC;
  border-radius: 3px;
  margin: 10px 0;
  margin-bottom: 60px;
}

.expenses-summary {
  background: white;
  /* margin: 10px 0; */
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.expense {
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 2px;
  border: 0.1px solid grey;
  box-shadow: .5px 1px;
  margin: 5px 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expense p {
  color: red;
  padding-top: 14px;
  text-align: center;
}

.expense-name-date {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0 !important;
}

.expense-name-date h3 {
  margin-bottom: 5px;
  margin-top: 3px;
}

.expense-name-date h6 {
  margin: 0 0;
}

.expenses-form-container {
  margin-top: 8%;
  margin-bottom: 30px;
}

.expenses-form {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 40%;
}

.expenses-form input {
  margin: 10px 0;
}

.expense-button {
  margin-left: 10px;
  border-radius: 4px;
  width: 50px;
  height: 30px;
}

.expenses-form label {
  margin-top: 6px;
  margin-bottom: -10px;
  color: #5C6070
}


/* DASHBOARD */

.expenses-list div {
  display: flex;
  padding-left: 5%;
} 

.expenses-sum {
  margin: 0px 0 !important;
  padding-right: 5%;
  color: red;
}

.expenses-summary {
  margin: 20px 0;
}

.expenses-summary h2 {
  margin: 0 0 !important; 
}

.delete-expense {
  margin-right: 5%;
  width: 30px;
  background:#ffffff;
  color:black;
  border:none;
  cursor:pointer;
}


/* TRIP LIST */

.trips {
  margin-top: 5%;
  margin-left: 5%;
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
}

.trip-card {
  min-width: 33%;
  max-width: 70%;
  margin: 10px 0;
}

.ui .card {
  width: 100%;
}

.selected-trip {
  display: flex;
  justify-content: center;
}

/* TRIP FORM */

.tripForm-container {
  width:80%;
  margin:0 auto;
  padding:2% 0%;
}

.tripForm {
  background:white;
  border:1px solid #B9BEC6;
  border-radius: 4px;
  width:60%;
  margin:0 auto;
  padding:2% 0%;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
}

.tripForm input {
  width:70%;
  border-radius:8px;
  background:#f9fbfc;
  margin:2% 0%;
  padding: 2% 2%;
}

.friend-list{
  display:flex;
  flex-wrap: wrap;
  width:70%;
}

.friend-list label{
  width:25%;
  color: #5C6070;
  text-align:left;
  margin:1% 0%;
}

.friend-list label input{
  width:25%;
  margin:0;
}

.addFriendButton {
  margin-top: 20px;
  margin-bottom: 10px;
}

.edit-trip {
  margin: 20px auto;
}
